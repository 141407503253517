.back-drop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(50, 50, 50, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 35vw;
  height: 30vh;
  padding: 0vh 10vh;
  background-color: rgb(235, 235, 235);
  border-radius: 1vh;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 1.5rem;
  box-shadow: 0 2px 10px 0 rgb(50, 50, 50), 0 2px 10px 0 rgb(50, 50, 50);
  border: 2px solid rgb(206, 206, 206);
}

.modal-text {
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  .modal-text {
    font-size: 1.1rem;
  }
}

.modal-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2vh;
  font-size: 1.2rem;
  font-weight: bold;
  color: rgb(50, 50, 50);
}

.modal-col {
  align-items: space-between;
  justify-content: space-between;

  width: 100%;
}

.modal-btn {
  padding: 1vh;
  border: 1px solid rgb(50, 50, 50);
  cursor: pointer;
  margin: 1vh;
  border-radius: 0.5vh;
  border: 2px solid rgb(206, 206, 206);
  width: 7vw;
}

@media (max-width: 1200px) {
  .modal-btn {
    font-size: 1rem;
  }
}

.modal-btn:hover {
  color: rgb(200, 200, 200);
  box-shadow: 0 2px 4px 0 rgb(206, 206, 206), 0 2px 6px 0 rgb(206, 206, 206);
}

.del {
  background-color: rgb(50, 50, 50);
  color: #fff;
}

.modal-x {
  position: absolute;
  top: 1vh;
  right: 0;
  width: 5vh;
  height: 5vh;
  cursor: pointer;
}

@font-face {
  font-family: "Mplus";
  font-weight: 900;
  src: local("Mplus"), url(./fonts/MPLUSRounded1c-Black.ttf) format("truetype");
}

body {
  margin: 0;
  text-align: center;
  background-color: rgb(241, 242, 243);
  box-sizing: content-box;
}

.container {
  position: fixed;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.left {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 25vw;
  height: auto;
  background-color: rgb(241, 242, 243);
  border-right: 0.25vw solid rgb(214, 214, 214);
  overflow: hidden;
}

.right {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 75vw;
  height: 100vh;
  height: max-content;
  background-color: rgb(50, 50, 50);
  overflow: hidden;
}

@media (max-width: 768px) {
  .left {
    display: none;
  }
  .right {
    position: fixed;
    width: 100%;
    height: auto;
  }
}

.leftArrow,
.rightArrow {
  display: flex;
  position: absolute;
  width: 90px;
  height: 90px;
  background-color: transparent;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  z-index: 250;
  color: rgb(255, 255, 255);
  font-family: Mplus;
  font-size: 1.5rem;
}

.leftArrow:hover,
.rightArrow:hover {
  background-color: rgba(134, 134, 134, 0.589);
  font-weight: bold;
}

.rightArrow {
  right: 0;
}

@media (max-width: 768px) {
  .leftArrow,
  .rightArrow {
    margin: 10px;
    width: 60px;
    height: 60px;
    border-radius: 15px;
    font-size: 1rem;
    background-color: rgba(134, 134, 134, 0.589);
    font-weight: bold;
    position: fixed;
  }
  .rightArrow {
    right: 10px;
  }

  .leftArrow {
    left: 10px;
  }
}

.btn-light {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4vh;
  height: 4vh;
  cursor: pointer;
  font-size: 1.2rem;
  text-align: center;
  border-radius: 0.5vh;
  border: 2px solid rgb(206, 206, 206);
  margin-right: 4px;
}

.btn-light :last-child {
  margin-right: 0px;
}

.btn-wide {
  margin-top: 30px;
  width: 75%;
  height: 40px;
}

.btn-light:active {
  animation: ani 0.3s ease-in-out;
}

.btn-light:hover {
  color: rgb(200, 200, 200);
  box-shadow: 0 2px 4px 0 rgb(206, 206, 206), 0 2px 6px 0 rgb(206, 206, 206);
}

.small-btn-open,
.small-btn-bars,
.small-btn-delete {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5vh;
  width: 4vh;
  padding-left: 0.6vh;
  padding-right: 0.6vh;
  margin-top: 0;
  background-color: rgb(214, 214, 214);
  color: rgb(50, 50, 50);
  border-radius: 0;
  cursor: pointer;
}

.small-btn-bars {
  display: none;
}

@media (max-width: 768px) {
  .small-btn-bars {
    position: absolute;
    display: flex;
    top: 0.5vh;
    background-color: transparent;
  }
}
.small-btn-open:hover,
.small-btn-delete:hover {
  color: #ffffff;
}

@media (max-width: 768px) {
  .small-btn-delete {
    display: none;
  }
}

@media (max-width: 320px) {
  .small-btn-open {
    display: none;
  }
}

@keyframes ani {
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.student-name-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(235, 235, 235);
  color: rgb(92, 98, 104);
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 1.1rem;
  height: 5vh;
}

@media (max-width: 1440px) {
  .student-name-container {
    height: 10vh;
  }
}

@media (max-width: 768px) {
  .student-name-container {
    z-index: 1000;
    height: 20vh;
    width: 100vw;
    box-shadow: 0px 2px 5px rgb(95, 95, 95);
  }
}

.top_btn_container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.and_yet_again_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

@media (max-width: 1440px) {
  .and_yet_again_container {
    flex-direction: column;
    height: 10vh;
  }
}

.select-part {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 425px) {
  .select-part {
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 20vh;
  }
}

.select {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
  padding-left: 1vw;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 1.1rem;
  height: 5vh;
  background-color: rgb(235, 235, 235);
  white-space: nowrap;
  width: fit-content;
}
.select:last-child {
  margin-right: 10px;
}

.select-student {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2vh;
  background-color: rgb(235, 235, 235);
  width: 100%;
  white-space: nowrap;
  margin-right: 10px;
}

@media (max-width: 1200px) {
  .select,
  .select-student {
    font-size: 0.9rem;
  }
  .option {
    font-size: 0.9rem;
  }
}

@media (max-width: 425px) {
  .select-student {
    margin-right: 0;
    padding-right: 0;
    padding-left: 1vw;
  }
}

.student-name,
.student-score {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left: 1vw;
}

@media (max-width: 768px) {
  .student-score {
    display: none;
  }
}

.evaluators {
  position: absolute;
  top: 7vh;
  right: 15vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 1vh;
  border-radius: 1vh;
  z-index: 2000;
  color: #000;
}

@media (max-width: 1440px) {
  .evaluators {
    top: 14vh;
  }
}

.score {
  display: inherit;
  flex-direction: inherit;
  align-items: center;
  justify-content: center;
  font-size: 2.5vh;
  margin-left: 1vw;
  cursor: default;
  width: 5vh;
  height: 5vh;
}

.score:hover {
  background-color: rgb(92, 98, 104);
  color: #fff;
}

select {
  display: flex;
  flex-direction: row;
  padding: 0.25vw;
  padding-right: 2vw;
  padding-left: 1vw;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  background-color: rgb(235, 235, 235);
  color: rgb(92, 98, 104);
  outline: none;
  border: none;
  appearance: none;
  font-size: 1.1rem;
  height: 5vh;
  cursor: pointer;
  background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='22' height='22' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='rgb(50,50,50)'/></g></svg>")
    no-repeat;
  background-position: right 0 top 54%;
  /* width: 100%; */
}

@media (max-width: 1200px) {
  select {
    font-size: 0.9rem;
    background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='rgb(50,50,50)'/></g></svg>")
      no-repeat;
    background-position: right -2px top 54%;
  }
}

@media (max-width: 420px) {
  select {
    font-size: 0.9rem;
    background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='rgb(50,50,50)'/></g></svg>")
      no-repeat;
    background-position: right -2px top 54%;
    text-overflow: ellipsis;
    width: 85vw;
  }
}

@media (max-width: 320px) {
  select {
    text-overflow: ellipsis;
    width: 95vw;
  }
}

.prof-row {
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .prof-row {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.ev-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.form {
  position: relative;
  width: 100%;
  height: 80px;
  flex-direction: column;
  justify-content: center;
  margin-top: 2vh;
}

@media (max-width: 425px) {
  .form {
    width: 85vw;
  }
}

.form-container {
  padding: 0;
  margin: 0;
  width: 100%;
}

.slider-name {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: bold;
  position: absolute;
  top: -15px;
}

.evaluator {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 95vh;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 20px;
}

.list-header {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: space-between;
  width: 75%;
  margin: 0 14px;
}

.sort_selector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

@media (max-width: 1200px) {
  .list-header {
    flex-direction: column;
  }
}

.ev-buttons {
  display: flex;
  flex-direction: row;
  width: 85%;
  align-items: center;
  justify-content: center;
  margin-top: 3vh;
  padding-bottom: 1vh;
}

.icon {
  color: rgb(100, 100, 100);
  cursor: pointer;
}

@media (max-width: 1440px) {
  .icon {
    width: 14px;
    height: 14px;
  }
}

.icon:hover {
  color: rgb(50, 50, 50);
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.list_for_students {
  width: 75%;
  margin-top: 20px;
}

input[type="file"] {
  display: none;
}

.prof-input {
  width: 75%;
  font-size: 1.2rem;
  text-align: center;
  border-radius: 1vh;
  padding: 0.5rem;
  margin: 0.5vw;
  outline: none;
  border: 2px solid rgb(228, 228, 228);
  box-sizing: border-box;
}

.input_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}

.title-group {
  font-family: Mplus;
}

.title {
  display: flex;
  justify-content: center;
  width: 90%;
  font-size: 1.5rem;
}

.title-login {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 90%;
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  .title-group {
    font-size: 0.8rem;
  }

  .title {
    display: flex;
    justify-content: center;
    width: 90%;
    font-size: 1.1rem;
  }
}
.bottom-button {
  display: flex;
  position: relative;
  bottom: 40vh;
  width: 20%;
  flex-direction: column;
  z-index: 200;
}

@media (max-width: 768px) {
  .bottom-button {
    display: none;
  }
}
.gal {
  bottom: 0;
  width: 100%;
  font-size: 1rem;
  padding: 10%;
}

.score-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1vh;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 1.4rem;
  margin-bottom: 1vw;
}

.prof-block {
  margin-bottom: 0.5vw;
  margin-left: 1vw;
}

.btn-upload {
  color: #b8b8b8;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  border: 2px solid #b8b8b8;
  padding: 1vh;
  cursor: pointer;
  padding-right: 2vw;
  padding-left: 2vw;
  border-radius: 1vh;
  margin-left: 10%;
  margin-right: 10%;
}

.btn-upload:hover {
  background-color: rgba(139, 139, 139, 0.8);
}

.loader {
  display: flex;
  position: relative;
  background-color: lawngreen;
  height: 0.5vh;
  align-self: flex-start;
  bottom: -10hv;
  z-index: 2500;
}

.info {
  padding-top: 0.5vh;
  color: #b8b8b8;
  font-size: 0.8rem;
}

.monkey {
  position: relative;
  width: 30px;
  height: auto;
  margin-right: 10px;
  margin-left: 1vw;
}

@media (max-width: 1200px) {
  .monkey {
    display: none;
  }
}

.ev-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: rgb(50, 50, 50);
  font-family: Mplus;
  width: 100%;
  padding-left: 5vw;
  height: 5vh;
}

@media (max-width: 1200px) {
  .ev-header {
    font-size: 14px;
  }
}

.scale {
  display: flex;
  flex-direction: row;
  color: #5c6268;
  margin-top: 5px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 1rem;
}

input[type="range"] {
  width: 100%;
  outline: none;
  -webkit-appearance: none;
}
input[type="range"]::-webkit-slider-thumb {
  position: relative;
  outline: none;
  border: none;
  height: 20px;
  width: 10px;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  bottom: 10px;
  background: rgb(203, 203, 204);
  border: 2px solid #262b30;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  border-top: 2px solid #262b30;
  background: rgb(255, 255, 255);
  border-radius: 1.3px;
}

.score-bulb {
  position: relative;
  top: -5px;
  height: 20px;
  width: 20px;
  font-size: 1.5rem;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: bold;
}
@media (max-width: 1440px) {
  .score-bulb {
    top: 5px;
    font-size: 1rem;
  }
}
@media (max-width: 1024px) {
  .score-bulb {
    top: 5px;
    font-size: 1rem;
  }
}

.list {
  margin-top: 1vh;
  width: 16vw;
  height: 0;
  background-color: transparent;
  border: none;
  outline: none;
  resize: none;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  z-index: -1;
}

.listcontainer {
  position: relative;
  width: 100%;
  height: 200px;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow-y: auto;
}

.list-wrapper {
  height: 200px;
  width: 78%;
}

.list-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* height: 2.5vh; */
  font-weight: 100;
}

@media (max-width: 1200px) {
  .list-item {
    font-size: 12px;
  }
}

.separator {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border-bottom: 1px solid rgb(50, 50, 50);
  padding-bottom: 0.5vh;
}

.values {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
  width: 100%;
  margin: 0 1rem;
}

.bold {
  font-weight: bold;
}

@media (max-width: 1200px) {
  .bigger {
    font-size: 0.9rem;
  }
  .bold {
    font-size: 0.9rem;
  }
  .small {
    font-size: 0.9rem;
    justify-content: space-between;
  }
  .values-box {
    font-size: 0.9rem;
    margin-top: 10px;
    margin-right: 0;
  }
  .values {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.small {
  font-size: 1rem;
}

.bigger {
  font-size: 1rem;
}

.clickable {
  cursor: pointer;
  margin-right: 10px;
}
.clickable:last-child {
  margin-right: 0;
}

@media (max-width: 1200px) {
  .clickable {
    font-size: 0.9rem;
  }
}

.bleak {
  color: #b8b8b8;
}

.modal {
  display: block;
  width: 100vw;
  height: 100vh;
  margin: 0;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  overflow-y: auto;
  object-fit: cover;
  box-sizing: border-box;
  background-color: #ffffff;
}

.hide {
  display: none;
}

.imagecontainer {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  height: 95vh;
  width: 75vw;
  overflow-y: auto;
  object-fit: contain;
  background-color: rgb(50, 50, 50);
}

.image-in {
  width: auto;
  height: 100%;
  cursor: zoom-in;
  z-index: 100;
  margin: auto;
  background-color: #ffffff;
}

@media (max-width: 768px) {
  .imagecontainer {
    height: 80vh;
    width: auto;
    overflow-x: auto;
    /* object-fit: fill; */
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .image-in {
    height: 100%;
    width: auto;
    z-index: 100;
    overflow-x: auto;
    pointer-events: none;
  }
}

.image-out {
  width: 100%;
  height: auto;
  cursor: zoom-out;
  z-index: 100;
}

.default {
  cursor: default;
}

.warning {
  margin-top: 1vh;
  color: red;
  font-weight: bold;
  font-size: 1.2rem;
}

@media (max-width: 1200px) {
  .warning {
    font-size: 0.9rem;
  }
}

.gallery-cell {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 2px solid rgb(78, 78, 78);
  background-repeat: no-repeat;
  background-position: 50% 30%;
  background-size: 50%;
}

.gallery {
  position: relative;
  box-sizing: border-box;
  padding: 6px 8px 10px 6px;
  width: 100%;
  display: grid;
  height: 95vh;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-gap: 1vh;
  overflow-y: auto;
  overflow-x: hidden;
}
@media (max-width: 1200px) {
  .gallery {
    grid-template-columns: repeat(4, 1fr);
    height: 90vh;
    width: 100vw;
  }
}

@media (max-width: 425px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
    height: 80vh;
  }
}

@media (max-width: 325px) {
  .gallery {
    grid-template-columns: repeat(1, 1fr);
    height: 80vh;
  }
}

.group_name {
  display: flex;
}

@media (max-width: 425px) {
  .group_name {
    display: none;
  }
}

.gallery-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  background-color: #fff;
  transform: scale(0.96);
}

.gallery-cell-dum {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transform: scale(0.5);
  position: relative;
  top: -10%;
}

.dum {
  position: relative;
  bottom: 20%;
  /* left: 50%; */
  transform: scale(0.5);
}

.gallery-selected {
  border: 2px solid rgb(255, 254, 254);
}

.square {
  width: 1.3vh;
  height: 1.3vh;
  margin: 1px;
  background-color: rgb(50, 50, 50);
}

.w {
  background-color: #fff;
}

.current-cell {
  border: 2px solid #fff;
}

.cell-unscored {
  border: 2px solid red;
}

.show-score {
  display: none;
}

.scores-detailed {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  font-size: 1.5vh;
  cursor: default;
}

.gallery-cell:hover .show-score {
  display: flex;
  padding-top: 3vh;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 500;
  left: 0;
  top: 0;
  font-size: 5vh;
  background-color: rgb(50, 50, 50, 0.65);
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: #fff;
  box-sizing: border-box;
}

.special {
  margin-top: 1vh;
  cursor: pointer;
  padding: 0.5vh;
  color: #5c6268;
}

.special:hover {
  background-color: white;
  color: #000;
  font-weight: bold;
}

.loading-img {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: #fff;
  z-index: 1900;
  background-color: rgb(50, 50, 50);
}

@media (max-width: 768px) {
  .loading-img {
    width: 100vw;
    height: 80vh;
    position: fixed;
  }
}

.ev_container {
  display: none;
}

@media (max-width: 768px) {
  .ev_container {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 50vw;
    z-index: 1000;
    background-color: rgb(241, 242, 243);
    top: 5vh;
  }
}

@media (max-width: 435px) {
  .ev_container {
    width: 100vw;
  }
}

.lds-dual-ring {
  margin-bottom: 1em;
  display: inline-block;
  width: 60px;
  height: 60px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 54px;
  height: 54px;
  margin: 4px;
  border-radius: 50%;
  border: 2px solid rgb(214, 214, 214);
  border-color: rgb(214, 214, 214) transparent rgb(214, 214, 214) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
